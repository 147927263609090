/* eslint-disable */
import React, { useState } from 'react';
import { Icon } from 'semantic-ui-react';
import searchValueImg from 'assets/images/search-by-value.png';
import searchValueImgDark from 'assets/images/search-by-value-dark.png';
import { block } from 'bem-cn';
import PropTypes from 'prop-types';
import { Filter } from './components/Filter';
import './styles.scss';

const b = block('sort-interface');

export const types = {
  notSort: 'not_sort',
  desc: 'DESC',
  asc: 'ASC',
};

const propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  hints: PropTypes.arrayOf(PropTypes.string),
  filterValue: PropTypes.string,
  sortValue: PropTypes.string,
  onChangeFilter: PropTypes.func.isRequired,
  onConfirmFilter: PropTypes.func.isRequired,
  onFocusFilter: PropTypes.func.isRequired,
  isResultSearchDefaultOpen: PropTypes.bool,
  onChangeSort: PropTypes.func.isRequired,
  hintsRender: PropTypes.func,
  isDisableHints: PropTypes.bool,
  postfixSortFieldName: PropTypes.string,
  transform: PropTypes.func.isRequired,
  isSortingDisabled: PropTypes.bool
};

const defaultProps = {
  filterValue: '',
  sortValue: null,
  isResultSearchDefaultOpen: false,
  hintsRender: null,
  isDisableHints: false,
  postfixSortFieldName: '',
  hints: [],
  isSortingDisabled: false
};

const couponKuBonusCoupon = ['yield1', 'yield2', 'autocallBonusCoupon'];

export const SortAndFilterInterface = ({
  name,
  label,
  hints,
  filterValue,
  sortValue,
  onChangeFilter,
  onConfirmFilter,
  onFocusFilter,
  isResultSearchDefaultOpen,
  onChangeSort,
  hintsRender,
  isDisableHints,
  postfixSortFieldName, isSortingDisabled,
  transform,
}) => {
  const [isOpenFilterPopup, setIsOpenFilterPopup] = useState(false);

  const isRevertSorting = couponKuBonusCoupon.includes(name);

  const toggleSortStateReverse = (state) => {
    switch (state) {
      case null:
        return 'DESC';

      case 'DESC':
        return 'ASC';

      case 'ASC':
        return null;

      default:
        return null;
    }
  };

  const toggleSortState = (state) => {
    switch (state) {
      case null:
        return 'ASC';

      case 'ASC':
        return 'DESC';

      case 'DESC':
        return null;

      default:
        return null;
    }
  };

  const getAlphabetIconName = (type) => {
    switch (type) {
      case types.notSort:
        return 'sort';
      case types.desc:
        return 'sort down';

      case types.asc:
        return 'sort up';

      default:
        return 'sort';
    }
  };

  const handleChangeSort = () => {
    const toggledSortState = toggleSortState(sortValue);
    const toggledSortStateReverse = toggleSortStateReverse(sortValue);

    onChangeSort({
      name: `${name}${postfixSortFieldName}`,
      type: isRevertSorting ? toggledSortStateReverse : toggledSortState,
      timestamp: new Date(),
    });
  };

  return (
    <>
      <Filter
        name={name}
        label={label}
        value={filterValue}
        isOpen={isOpenFilterPopup}
        onClose={() => setIsOpenFilterPopup(false)}
        hints={hints}
        onChange={onChangeFilter}
        onConfirm={onConfirmFilter}
        isResultSearchDefaultOpen={isResultSearchDefaultOpen}
        onFocus={onFocusFilter}
        hintsRender={hintsRender}
        isDisableHints={isDisableHints}
        transform={transform}
      />
      <div className={b()}>
          <button
            onClick={handleChangeSort}
            type="button"
            className={b('alphabet')}
            disabled={isSortingDisabled}
          >
            <Icon
              name={getAlphabetIconName(sortValue)}
              className={b('alphabet-icon').toString()}
            />
          </button>
        <button
          className={b('filter-btn')}
          onClick={() => setIsOpenFilterPopup(true)}
          type="button"
        >
          <img
            src={filterValue ? searchValueImgDark : searchValueImg}
            alt="search value"
            className={b('search-value')}
          />
        </button>
      </div>
    </>
  );
};

SortAndFilterInterface.propTypes = propTypes;
SortAndFilterInterface.defaultProps = defaultProps;
