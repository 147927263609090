export default {
  main: 'Цифровая платформа',
  dashboard: 'ЦП - выбор продукта',
  preliminaryCalculation: 'ЦП - оформление',
  insurer: 'ЦП - страхователь',
  insured: 'ЦП - застрахованный',
  beneficiaries: 'ЦП - выгодоприобретатели',
  executionData: 'ЦП - печать договора',
  sales: 'ЦП - мои продажи',
  documentCheckStatus: 'ЦП - статус проверки документов',
  customerReportingService: 'ЦП - клиентские отчеты',
  docsTransfer: 'ЦП - документооборот',
  administration: 'ЦП - администрирование',
  importForm: 'ЦП - импорт',
  eventsTitle: 'ЦП - события',
  lockingForm: 'ЦП - задачи блокировок',
  contests: 'ЦП - конкурсы',
  clientTestTitle: 'ЦП - Тестирование',
};
