import React from 'react';
import PropTypes from 'prop-types';
import { PopupContainer } from 'components';
import { Button } from 'semantic-ui-react';
import { block } from 'bem-cn';
import { LANG_DICTIONARY } from 'consts';

import './styles.scss';

const b = block('resend-test-link-popup');

const { CLIENT_TEST_RESEND_LINK_CONFIRMATION_MESSAGE, CANCEL_WORD, OK_WORD } = LANG_DICTIONARY;

const propTypes = {
  isActive: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  className: PropTypes.string,
};

const defaultProps = { isActive: false, className: b() };

const ResendTestLinkPopup = ({
  isActive,
  onClose,
  onOk,
  className,
}) => (
  <PopupContainer isOpen={isActive} className={className} close={onClose}>
    <div className={b('message')}>
      {CLIENT_TEST_RESEND_LINK_CONFIRMATION_MESSAGE}
    </div>
    <div className={b('controls')}>
      <Button
        type="submit"
        className={b('sendButton').toString()}
        onClick={onOk}
      >
        { OK_WORD }
      </Button>
      <Button type="button" className={b('btn-cancel').toString()} onClick={onClose}>
        {CANCEL_WORD}
      </Button>
    </div>
  </PopupContainer>
);

ResendTestLinkPopup.propTypes = propTypes;
ResendTestLinkPopup.defaultProps = defaultProps;

export default ResendTestLinkPopup;
