import React, { Component } from 'react';
import block from 'bem-cn';
import PropTypes from 'prop-types';
import { FundItem } from 'components';
import { ContestInviteBanner } from 'containers';
import { withCustomRouter } from 'HOC';
import {
  resetValidationTerrorist,
  getProducts,
  resetForms,
  setInitFormBeneficiary,
  clearStoreNszProducts,
  clearStoreKszProducts,
  clearStoreAgentData,
} from 'redux/rootActions';
import { connect } from 'react-redux';
import './styles.scss';
import { setClientTestingParams } from 'redux/actions';
import ClientTestContactForm from 'components/_popups/ClientTestContactForm';
import TestNextDateMessage from 'components/_popups/TestNextDateMessage';

const b = block('dashboard');

const defaultProps = {
  products: [],
  getProductsAction: () => {},
  resetFormsAction: () => {},
  resetErrorAction: () => {},
  resetFormBeneficiary: () => {},
  clearStoreKszParameters: () => null,
  match: {},
  setClientTestingParamsAction: () => {},
  isNeedTesting: false,
  clientTestNextDate: null,
};

const propTypes = {
  getProductsAction: PropTypes.func,
  resetFormsAction: PropTypes.func,
  resetErrorAction: PropTypes.func,
  products: PropTypes.arrayOf(PropTypes.object),
  resetFormBeneficiary: PropTypes.func,
  match: PropTypes.object,
  clearStoreNszCalculator: PropTypes.func.isRequired,
  clearStoreAgent: PropTypes.func.isRequired,
  clearStoreKszParameters: PropTypes.func,
  setClientTestingParamsAction: PropTypes.func,
  isNeedTesting: PropTypes.bool,
  clientTestNextDate: PropTypes.string,
};

class Dashboard extends Component {
  constructor(props) {
    super(props);
    const {
      getProductsAction,
      resetFormsAction,
      resetErrorAction,
      resetFormBeneficiary,
      match: { params: { id } },
      clearStoreNszCalculator,
      clearStoreKszParameters,
      clearStoreAgent,
    } = props;

    resetFormsAction();
    resetErrorAction();
    getProductsAction(id);
    resetFormBeneficiary();
    clearStoreNszCalculator();
    clearStoreKszParameters();
    clearStoreAgent();
  }

  getTableLayout = (products) => products
    .reduce((table, product) => {
      if (!product.isShow) {
        return table;
      }

      const {
        productFormOrderLine,
        productFormOrderPos,
      } = product;

      if (!productFormOrderLine || !productFormOrderPos) return table;

      const positionX = productFormOrderPos - 1;
      const positionY = productFormOrderLine - 1;

      const rebuildTable = [...table];
      if (!rebuildTable[positionY]) rebuildTable[positionY] = [];
      rebuildTable[positionY][positionX] = product;

      return rebuildTable;
    }, [])

  withEmpty = (row) => {
    const rebuildRow = [...row];
    // eslint-disable-next-line
    for(let key in rebuildRow) {
      if (!rebuildRow[key]) {
        rebuildRow[key] = { id: key, product: null };
      } else {
        rebuildRow[key] = {
          product: rebuildRow[key],
          id: key,
        };
      }
    }

    return rebuildRow;
  }

  onCloseTestAccessRequest = () => {
    const { setClientTestingParamsAction } = this.props;
    setClientTestingParamsAction({ isNeedTesting: false });
  }

  onCloseTestNextDateMessage = () => {
    const { setClientTestingParamsAction } = this.props;
    setClientTestingParamsAction({ clientTestNextDate: null, clientTestRecordId: 0 });
  }

  render() {
    const { products, isNeedTesting, clientTestNextDate } = this.props;
    const tableLayout = this.getTableLayout(products);

    return (
      <div className={b()}>
        <ClientTestContactForm
          isActive={isNeedTesting}
          onClose={this.onCloseTestAccessRequest}
          className={b()}
        />
        <TestNextDateMessage
          isActive={clientTestNextDate}
          onClose={this.onCloseTestNextDateMessage}
          className={b()}
          nextDate={clientTestNextDate}
        />
        <ContestInviteBanner />
        <div className={b('form')}>
          <table className={b('table')}>
            <tbody>
              { tableLayout.map((row, rowIndex) => (
                // eslint-disable-next-line
                <tr key={`row${rowIndex}`}>
                  {row ? this.withEmpty(row).map((item) => {
                    if (!item.product) return <td key={`col${item.id}`}><div className={b('empty-block')} /></td>;

                    return (<td key={`col${item.id}`}><FundItem {...item.product} key={item.product.id} maxHeight /></td>);
                    // eslint-disable-next-line
                  }) : <td key={`col${rowIndex}`}><div className={b('empty-block')} /></td>}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { checkoutReducer, shared } = state;

  return {
    ...checkoutReducer,
    ...shared,
    isNeedTesting: state.authReducer.isNeedTesting,
    clientTestNextDate: state.authReducer.clientTestNextDate,
  };
}

const mapDispatchToProps = {
  getProductsAction: getProducts,
  resetFormsAction: resetForms,
  resetErrorAction: resetValidationTerrorist,
  resetFormBeneficiary: setInitFormBeneficiary,
  clearStoreNszCalculator: clearStoreNszProducts,
  clearStoreKszParameters: clearStoreKszProducts,
  clearStoreAgent: clearStoreAgentData,
  setClientTestingParamsAction: setClientTestingParams,
};

Dashboard.defaultProps = defaultProps;
Dashboard.propTypes = propTypes;

export default withCustomRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));
