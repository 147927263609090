import { getReduxFormChangeActionType } from 'hooks/useReduxForm';
import { STRATEGY_FIELD_NAME } from 'components/_forms/_constructorForms/BasketCreatingForm/components/Strategy/fieldNames';
import { v4 as uuidV4 } from 'uuid';
import {
  all,
  put,
  select,
  takeEvery,
  takeLatest,
  throttle,
} from 'redux-saga/effects';
import {
  CALCULATE_COUPON,
  CHANGE_CURRENCY,
  CHECK_MULTIPLE_ASSETS,
  CLEAR_MIN_INSURANCE_SUM,
  GET_ACTIVES,
  GET_MIN_INSURANCE_SUM_FORM_CREATING,
  GET_PAYOUT_FREQUENCY,
  GET_PRODUCT_TYPES_FORM_CREATING,
  GET_REQUEST_DATA_FOR_COPY,
  GET_TERMS,
  SET_PRODUCT_TYPE_FORM_CREATING,
  GET_GENERAL_ASSET_CLASS,
  GET_MAX_GUARANTEE_LEVEL,
  RECALCULATE_COUPON,
} from 'redux/basketConstructor/types';
import {
  selectCurrencyCode,
  selectGuaranteedPayouts,
  selectPartnersInsProductId,
  selectProductOptionOptions,
  selectProductTypeByCode,
  selectProductTypeCode,
  selectStrategyForms,
  selectStrategyUuids,
  selectGuaranteeLevel,
  selectOnlyFavorite,
  selectRegularBasketsPagination,
  selectReadyBasketsPagination,
  selectCalculatedBasketsPagination,
  selectRegularBasketsFilters,
  selectRegularBasketsSorting,
  selectReadyBasketsFilters,
  selectReadyBasketsSorting,
  selectCalculatedBasketsFilters,
  selectCalculatedBasketsSorting,
  selectSelectedBasketId, selectProductPayouts,
} from 'redux/basketConstructor/selectors';
import { apiCall, getError } from 'helpers';
import { API, CURRENCY_CODE_REF, ROUTES } from 'consts';
import {
  calculateProductPayoutValue,
} from 'components/_forms/_constructorForms/BasketCreatingForm/components/Strategy/helpers';
import { getOptionTypes, getCurrencies, DEFAULT_THROTTLE_VALUE } from './common';
import {
  copyGuaranteedPayouts,
  removeLastStrategies,
  setChildrenStrategyData,
  setMainStrategyData,
  setProductTypeFormCreating,
  getCalculatedPersonBaskets,
  getReadyBaskets,
  getRegularBaskets,
} from '../actions';
import { getGlobalHistory } from '../../../components';

function* getBasketActives({ payload: { term, refCurrencyCode, strategyUuid } }) {
  try {
    if (!term) {
      return [];
    }

    yield put({ type: GET_ACTIVES.start });

    const { data } = yield apiCall({
      type: 'GET',
      url: API.GET_BASKET_ACTIVES,
      query: { term, refCurrencyCode },
    });

    yield put({ type: GET_ACTIVES.success, payload: { data, strategyUuid } });

    return data;
  } catch (e) {
    yield put({ type: GET_ACTIVES.fail });

    return [];
  }
}

function sideEffectChangePayoutId({
  payload: {
    formContext: {
      batchChangeActions,
    },
  },
}) {
  batchChangeActions
    .add(STRATEGY_FIELD_NAME.BARRIERS, [])
    .add(STRATEGY_FIELD_NAME.BARRIER_MANUAL_ERROR, {})
    .exec();
}

function sideEffectChangeTerm({
  payload: {
    formContext,
  },
}) {
  formContext.batchChangeActions
    .add(STRATEGY_FIELD_NAME.BARRIERS, [])
    .add(STRATEGY_FIELD_NAME.BARRIER_MANUAL_ERROR, {})
    .add(STRATEGY_FIELD_NAME.ASSET_CODES, [])
    .exec();
}

function sideEffectChangeOptionType({
  payload: {
    formContext: {
      batchChangeActions,
    },
  },
}) {
  batchChangeActions
    .add(STRATEGY_FIELD_NAME.PARTICIPATION_COEFFICIENT, '')
    .add(STRATEGY_FIELD_NAME.COUPON, '')
    .add(STRATEGY_FIELD_NAME.PAYOUT_CODE, null)
    .add(STRATEGY_FIELD_NAME.BARRIERS, [])
    .add(STRATEGY_FIELD_NAME.BARRIER_MANUAL_ERROR, {})
    .add(STRATEGY_FIELD_NAME.BARRIER_AUTOCALL, 0)
    .add(STRATEGY_FIELD_NAME.BONUS_COUPON, 0)
    .add(STRATEGY_FIELD_NAME.LOOKBACK_DATA, [])
    .add(STRATEGY_FIELD_NAME.LOOKBACK_FLAG, false)
    .exec();
}

function* sideEffectChangeCurrency({ payload: { currencyCode, productId } }) {
  yield put({ type: CHANGE_CURRENCY.success, payload: { currencyCode, productId } });
  const strategies = yield select(selectStrategyForms);
  strategies.forEach(({ formUtils }) => {
    formUtils.batchChangeActions
      .add(STRATEGY_FIELD_NAME.TERM, null)
      .exec();
  });
}

function* getTerms({ payload: { productId, currencyCode } }) {
  try {
    yield put({ type: GET_TERMS.start });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.GET_BASKET_TERMS,
      query: { productId, currencyCode },
    });

    yield put({ type: GET_TERMS.success, payload: data });

    return data;
  } catch (e) {
    yield put({ type: GET_TERMS.fail, payload: getError(e) });

    return [];
  }
}

function* sideEffectsChangeAnyInput({ payload: { reducerPath } }) {
  const [mainUuid] = yield select(selectStrategyUuids);
  const isMain = reducerPath.includes(mainUuid);

  if (isMain) {
    yield put({ type: CLEAR_MIN_INSURANCE_SUM });
  }
}

function* getPayoutFrequency({ payload: { productId, termId } }) {
  try {
    yield put({ type: GET_PAYOUT_FREQUENCY.start });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.GET_BASKET_FREQUENCY,
      query: { productId, termId },
    });
    yield put({ type: GET_PAYOUT_FREQUENCY.success, payload: data });

    return data;
  } catch (e) {
    yield put({ type: GET_PAYOUT_FREQUENCY.fail, payload: getError(e) });

    return [];
  }
}

function* calculateCoupon() {
  try {
    const {
      isValid,
      strategies,
    } = yield validateForm();
    const refProductTypeCode = yield select(selectProductTypeCode);
    const currencyCode = yield select(selectCurrencyCode);
    const partnersInsProductId = yield select(selectPartnersInsProductId);
    const guaranteePayouts = yield select(selectGuaranteedPayouts);
    const guaranteedPayouts = guaranteePayouts?.map((item) => ({
      paymentTerm: item.paymentTerm,
      value: Number(item.value) || 0,
    }));

    const productOptions = yield select(selectProductOptionOptions);

    if (!isValid) {
      const err = JSON.stringify({
        response: {
          status: 1,
          data: {
            message: {
              RU: 'Для выбранной периодичности наблюдения опция лукбэка недоступна',
            },
          },
        },
      });
      yield put({
        type: CALCULATE_COUPON.fail,
        payload: getError(new Error(err)),
      });

      return null;
    }

    const [
      main,
      ...child
    ] = strategies;

    const body = {
      refProductTypeCode,
      partnersInsProductId,
      guaranteedPayouts,
      main: {
        refIszOptionTypeCode: main[STRATEGY_FIELD_NAME.PRODUCT_OPTION_TYPE_CODE],
        assets: main[STRATEGY_FIELD_NAME.ASSET_CODES],
        term: main[STRATEGY_FIELD_NAME.TERM],
        currencyCode,
        payoutFreqCode: main[STRATEGY_FIELD_NAME.PAYOUT_CODE],
        lookbackFlag: main[STRATEGY_FIELD_NAME.LOOKBACK_FLAG],
        barriers: main[STRATEGY_FIELD_NAME.BARRIERS],
        ...(main[STRATEGY_FIELD_NAME.BARRIER_AUTOCALL] && {
          autocallBarrier: Number(main[STRATEGY_FIELD_NAME.BARRIER_AUTOCALL]),
        }),
        autocallBonusCoupon: Number(main[STRATEGY_FIELD_NAME.BONUS_COUPON]),
        notionalBarrier: Number(main[STRATEGY_FIELD_NAME.NOTIONAL_BARRIER]),
        gearedBarrier: Number(main[STRATEGY_FIELD_NAME.GEARED_BARRIER]),
        growthCap: Number(main[STRATEGY_FIELD_NAME.GROWTH_CAP]) || 0,
      },
      child: child.map((item) => ({
        refIszOptionTypeCode: item[STRATEGY_FIELD_NAME.PRODUCT_OPTION_TYPE_CODE],
        ...(main[STRATEGY_FIELD_NAME.BARRIER_AUTOCALL] && {
          autocallBarrier: Number(main[STRATEGY_FIELD_NAME.BARRIER_AUTOCALL]),
        }),
        autocallBonusCoupon: Number(item[STRATEGY_FIELD_NAME.BONUS_COUPON]),
        notionalBarrier: Number(item[STRATEGY_FIELD_NAME.NOTIONAL_BARRIER]),
        gearedBarrier: Number(item[STRATEGY_FIELD_NAME.GEARED_BARRIER]),
        growthCap: Number(item[STRATEGY_FIELD_NAME.GROWTH_CAP]),
        assets: item[STRATEGY_FIELD_NAME.ASSET_CODES],
        term: item[STRATEGY_FIELD_NAME.TERM],
        payoutFreqCode: item[STRATEGY_FIELD_NAME.PAYOUT_CODE],
        lookbackFlag: item[STRATEGY_FIELD_NAME.LOOKBACK_FLAG],
        barriers: item[STRATEGY_FIELD_NAME.BARRIERS],
        ...(productOptions.find((option) => option.value ===
            item[STRATEGY_FIELD_NAME.PRODUCT_OPTION_TYPE_CODE]).isCoupon) && {
          coupon: Number(String(item[STRATEGY_FIELD_NAME.COUPON]).replace(',', '.')),
        },
        ...(productOptions.find((option) => option.value ===
            item[STRATEGY_FIELD_NAME.PRODUCT_OPTION_TYPE_CODE]).isKu) && {
          participationCoefficient: Number(String(item[STRATEGY_FIELD_NAME.PARTICIPATION_COEFFICIENT]).replace(',', '.')),
        },
      })),
    };

    yield put({ type: CALCULATE_COUPON.start });
    const { data } = yield apiCall({
      url: API.CALCULATE_BASKET,
      type: 'POST',
      body,
    });
    yield put({
      type: CALCULATE_COUPON.success,
      payload: {
        ...data,
        refIszOptionTypeCode: body.main.refIszOptionTypeCode,
        currencyCode,
      },
    });
  } catch (e) {
    if (!e.response) {
      const err = JSON.stringify({
        response: {
          status: 504,
          data: {
            message: {
              RU: 'Сервис временно недоступен. Попробуйте позже.',
            },
          },
        },
      });

      yield put({
        type: CALCULATE_COUPON.fail,
        payload: getError(new Error(err)),
      });
    } else yield put({ type: CALCULATE_COUPON.fail, payload: getError(e) });
  }

  return null;
}

function* recalculateCoupon({ payload }) {
  try {
    const refProductTypeCode = yield select(selectProductTypeCode);
    const currencyCode = yield select(selectCurrencyCode);
    const warrantyLevel = yield select(selectGuaranteeLevel);
    const id = yield select(selectSelectedBasketId);

    const body = {
      partnersInsProductId: payload.productId,
      refProductTypeCode,
      warrantyLevel,
    };

    yield put({ type: RECALCULATE_COUPON.start });
    const { data } = yield apiCall({
      url: API.RECALCULATE_BASKET(id),
      type: 'POST',
      body,
    });
    yield put({
      type: RECALCULATE_COUPON.success,
      payload: {
        ...data,
      },
    });

    const getBasketsParams = {
      partnersInsProductId: payload.productId,
      refCurrencyCode: currencyCode,
      refIszOptionTypeCode: payload.optionCode,
      refProductTypeCode,
      warrantyLevel,
    };

    if (payload.basket.isReady) {
      const paginationParams = yield select(selectReadyBasketsPagination);
      const readyFilters = yield select(selectReadyBasketsFilters);
      const readySort = yield select(selectReadyBasketsSorting);

      yield put(getReadyBaskets({
        ...getBasketsParams,
        ...paginationParams,
        readySort,
        readyFilters,
      }));
    }
    if (payload.basket.isRegular) {
      const paginationParams = yield select(selectRegularBasketsPagination);
      const regularFilters = yield select(selectRegularBasketsFilters);
      const regularSort = yield select(selectRegularBasketsSorting);

      yield put(getRegularBaskets({
        ...getBasketsParams,
        ...paginationParams,
        regularSort,
        regularFilters,
      }));
    }
    if (payload.basket.isCalculated) {
      const isOnlyFavorite = yield select(selectOnlyFavorite);
      const paginationParams = yield select(selectCalculatedBasketsPagination);
      const calculatedFilters = yield select(selectCalculatedBasketsFilters);
      const calculatedSort = yield select(selectCalculatedBasketsSorting);

      yield put(getCalculatedPersonBaskets({
        ...getBasketsParams,
        ...paginationParams,
        isOnlyFavorite,
        sort: calculatedSort,
        filters: calculatedFilters,
      }));
    }
  } catch (e) {
    if (!e.response) {
      const err = JSON.stringify({
        response: {
          status: 504,
          data: {
            message: {
              RU: 'Сервис временно недоступен. Попробуйте позже.',
            },
          },
        },
      });

      yield put({
        type: CALCULATE_COUPON.fail,
        payload: getError(new Error(err)),
      });
    } else yield put({ type: RECALCULATE_COUPON.fail, payload: getError(e) });
  }

  return null;
}

function* validateForm() {
  const forms = yield select(selectStrategyForms);

  const productPayouts = yield select(selectProductPayouts);

  return forms.reduce((acc, { formUtils, values }) => {
    formUtils.setAllTouched();

    const productPayout = productPayouts
      .find((pp) => pp.value === values[STRATEGY_FIELD_NAME.PAYOUT_CODE]);
    const productPayoutValue = calculateProductPayoutValue(productPayout.key);
    // eslint-disable-next-line
    const isPeriodicitySizeValid = values[STRATEGY_FIELD_NAME.LOOKBACK_FLAG] ? (Math.max(...values[STRATEGY_FIELD_NAME.LOOKBACK_DATA].map((ld) => ld.periodLength)) + 45) < productPayoutValue : true;

    const isValidForm = acc.isValid &&
      !formUtils.validateValues(values) &&
      isPeriodicitySizeValid;

    return {
      strategies: [...acc.strategies, values],
      isValid: isValidForm,
    };
  }, { isValid: true, strategies: [] });
}

function* setProductType({ payload: { productId, refProductTypeCode } }) {
  yield put({ type: SET_PRODUCT_TYPE_FORM_CREATING.success, payload: refProductTypeCode });

  const productType = yield select((state) => selectProductTypeByCode(state, refProductTypeCode));
  const strategiesUuids = yield select(selectStrategyUuids);
  const strategiesCountForRemove = strategiesUuids.length - productType.maxStrategiesAmount;

  if (strategiesCountForRemove > 0) {
    yield put(removeLastStrategies(strategiesCountForRemove));
  }

  yield getOptionTypes({ payload: { productId, refProductTypeCode } });

  const currencies = yield getCurrencies({ payload: { productId, refProductTypeCode } });
  const currencyCode = yield select(selectCurrencyCode);
  const isExistCurrency = currencies.some((item) => item.currencyCode === currencyCode);

  if (!isExistCurrency) {
    yield sideEffectChangeCurrency({
      payload: {
        productId,
        currencyCode: CURRENCY_CODE_REF.ROUBLE,
      },
    });

    return null;
  }

  yield getTerms({ payload: { productId, currencyCode } });

  return null;
}

function* getMinInsuranceSum({ payload: { qualification, productId } }) {
  try {
    const [mainStrategy] = yield select(selectStrategyForms);
    const currency = yield select(selectCurrencyCode);
    const {
      values,
      values: {
        [STRATEGY_FIELD_NAME.PRODUCT_OPTION_TYPE_CODE]: productOptionTypeId,
        [STRATEGY_FIELD_NAME.ASSET_CODES]: assets,
        [STRATEGY_FIELD_NAME.TERM]: term,
        [STRATEGY_FIELD_NAME.PAYOUT_CODE]: refInsDateUnitCode,
        [STRATEGY_FIELD_NAME.BARRIERS]: barriers,
      },
      formUtils: {
        setAllTouched,
        validateValues,
      },
    } = mainStrategy;
    setAllTouched();

    const errors = validateValues(values);

    const isValidForm = !errors;

    if (isValidForm) {
      yield put({ type: GET_MIN_INSURANCE_SUM_FORM_CREATING.start });
      const { data } = yield apiCall({
        url: API.GET_MIN_PAY_BY_PARAMETERS,
        type: 'GET',
        query: {
          currency,
          assets,
          term,
          basketBarriersText: barriers.length ? barriers.join('-') : undefined,
          refInsDateUnitCode: refInsDateUnitCode || undefined,
          productOptionType: productOptionTypeId,
          productId,
          qualification,
        },
      });

      yield put({
        type: GET_MIN_INSURANCE_SUM_FORM_CREATING.success,
        payload: { result: data, isMinInsuranceSumClicked: true },
      });
    }
  } catch (e) {
    yield put({ type: GET_MIN_INSURANCE_SUM_FORM_CREATING.fail, payload: getError(e) });
  }
}

function* getRequestDataForCopy({
  payload: {
    insLineIszCalcRequestId,
    childInsLineIszCalcRequestIds,
    refCurrencyCode,
    partnersInsProductId,
    refProductTypeCode,
    garantSchedule,
  },
}) {
  try {
    getGlobalHistory().push(ROUTES.constructorIszCreating({ id: partnersInsProductId }));
    yield put({ type: GET_REQUEST_DATA_FOR_COPY.start });
    const { data: { main, child } } = yield apiCall({
      url: API.REQUEST_DATA,
      type: 'GET',
      query: {
        childInsLineIszCalcRequestIds,
        refCurrencyCode,
        partnersInsProductId,
        mainInsLineIszCalcRequestId: insLineIszCalcRequestId,
      },
    });
    yield all([
      getCurrencies({
        payload: {
          productId: partnersInsProductId,
          refProductTypeCode,
        },
      }),
      getTerms({
        payload: {
          productId: partnersInsProductId,
          currencyCode: refCurrencyCode,
        },
      }),
    ]);

    const savingSages = [];

    if (main) {
      const strategyState = yield getMainStrategyState(main);
      savingSages.push(put(setMainStrategyData(strategyState)));
    }

    yield put(copyGuaranteedPayouts(garantSchedule));

    const childrenStrategies = yield all(child.map(
      (item) => getChildStrategyState(item, refCurrencyCode),
    ));
    savingSages.push(put(setChildrenStrategyData(childrenStrategies)));

    yield all(savingSages);
    yield put({ type: GET_REQUEST_DATA_FOR_COPY.success });
  } catch (e) {
    yield put({ type: GET_REQUEST_DATA_FOR_COPY.fail, payload: getError(e) });
  }
}

function* checkMultipleAssetClass({ payload: { assets, uuid } }) {
  try {
    yield put({ type: CHECK_MULTIPLE_ASSETS.start });
    const { data } = yield apiCall({
      type: 'POST',
      url: API.CHECK_MULTIPLE_ASSETS,
      body: { assets },
    });

    yield put({ type: CHECK_MULTIPLE_ASSETS.success, payload: { data, uuid } });

    if (data === false) {
      yield getGeneralAssetClass({ payload: { assets, uuid } });
    }

    return data;
  } catch (e) {
    yield put({ type: CHECK_MULTIPLE_ASSETS.fail, payload: getError(e) });

    return [];
  }
}

function* getGeneralAssetClass({ payload: { assets, uuid } }) {
  try {
    yield put({ type: GET_GENERAL_ASSET_CLASS.start });
    const { data } = yield apiCall({
      type: 'POST',
      url: API.GET_GENERAL_ASSET_CLASS,
      body: { assets },
    });

    yield put({ type: GET_GENERAL_ASSET_CLASS.success, payload: { data, uuid } });
  } catch (e) {
    yield put({ type: GET_GENERAL_ASSET_CLASS.fail, payload: getError(e) });
  }
}

function getMainStrategyState(data) {
  return {
    [STRATEGY_FIELD_NAME.PRODUCT_OPTION_TYPE_CODE]: data.refIszOptionTypeCode,
    [STRATEGY_FIELD_NAME.TERM]: data.term,
    [STRATEGY_FIELD_NAME.ASSET_CODES]: data.assets,
    [STRATEGY_FIELD_NAME.BARRIERS]: data.barriers,
    [STRATEGY_FIELD_NAME.PAYOUT_CODE]: data.payoutFreqCode,
    [STRATEGY_FIELD_NAME.LOOKBACK_FLAG]: data.lookbackFlag,
    [STRATEGY_FIELD_NAME.ASSET_OPTIONS]: [],
    [STRATEGY_FIELD_NAME.BARRIER_AUTOCALL]: data.autocallBarrier,
    [STRATEGY_FIELD_NAME.BONUS_COUPON]: data.autocallBonusCoupon,
    [STRATEGY_FIELD_NAME.IS_MULTIPLE_ASSET_CLASS]: data.isMultipleAssetClass,
    [STRATEGY_FIELD_NAME.NOTIONAL_BARRIER]: data.notionalBarrier,
    [STRATEGY_FIELD_NAME.GEARED_BARRIER]: data.gearedBarrier,
    [STRATEGY_FIELD_NAME.LOOKBACK_DATA]: data.lookbackData,
    [STRATEGY_FIELD_NAME.GROWTH_CAP]: data.growthCap,
  };
}

function getChildStrategyState(child) {
  return {
    uuid: uuidV4(),
    data: {
      [STRATEGY_FIELD_NAME.PRODUCT_OPTION_TYPE_CODE]: child.refIszOptionTypeCode,
      [STRATEGY_FIELD_NAME.TERM]: child.term,
      [STRATEGY_FIELD_NAME.ASSET_CODES]: child.assets,
      [STRATEGY_FIELD_NAME.BARRIERS]: child.barriers,
      [STRATEGY_FIELD_NAME.PAYOUT_CODE]: child.payoutFreqCode,
      [STRATEGY_FIELD_NAME.LOOKBACK_FLAG]: child.lookbackFlag,
      [STRATEGY_FIELD_NAME.PARTICIPATION_COEFFICIENT]: child.partitionCoeff,
      [STRATEGY_FIELD_NAME.COUPON]: child.coupon,
      [STRATEGY_FIELD_NAME.ASSET_OPTIONS]: [],
      [STRATEGY_FIELD_NAME.BARRIER_AUTOCALL]: child.autocallBarrier,
      [STRATEGY_FIELD_NAME.BONUS_COUPON]: child.autocallBonusCoupon,
      [STRATEGY_FIELD_NAME.NOTIONAL_BARRIER]: child.notionalBarrier,
      [STRATEGY_FIELD_NAME.GEARED_BARRIER]: child.gearedBarrier,
    },
  };
}

function* getMaxGuaranteeLevel({ payload: { currencyCode, productId } }) {
  try {
    yield put({ type: GET_MAX_GUARANTEE_LEVEL.start });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.GET_MAX_GUARANTEE_LEVEL,
      query: { productId, currencyCode },
    });

    yield put({ type: GET_MAX_GUARANTEE_LEVEL.success, payload: data });
  } catch (e) {
    yield put({ type: GET_MAX_GUARANTEE_LEVEL.fail, payload: getError(e) });
  }
}

export function* getProductTypes({ payload }) {
  try {
    yield put({ type: GET_PRODUCT_TYPES_FORM_CREATING.start });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.GET_BASKET_PRODUCT_TYPES,
      query: { productId: payload },
    });
    yield put({ type: GET_PRODUCT_TYPES_FORM_CREATING.success, payload: data });
    const productTypeCode = yield select(selectProductTypeCode);

    if (!productTypeCode && data.length) {
      yield put(setProductTypeFormCreating(payload, data[0].code));
    }
  } catch (e) {
    yield put({ type: GET_PRODUCT_TYPES_FORM_CREATING.fail, payload: getError(e) });
  }
}

export function* formCreatingSaga() {
  yield takeLatest(SET_PRODUCT_TYPE_FORM_CREATING.start, setProductType);
  yield takeLatest(CALCULATE_COUPON.request, calculateCoupon);
  yield takeLatest(RECALCULATE_COUPON.request, recalculateCoupon);
  yield throttle(500, GET_MIN_INSURANCE_SUM_FORM_CREATING.request, getMinInsuranceSum);
  yield throttle(DEFAULT_THROTTLE_VALUE, GET_TERMS.request, getTerms);
  yield throttle(DEFAULT_THROTTLE_VALUE, GET_PAYOUT_FREQUENCY.request, getPayoutFrequency);
  yield takeEvery(GET_ACTIVES.request, getBasketActives);
  yield takeEvery(CHECK_MULTIPLE_ASSETS.request, checkMultipleAssetClass);
  yield takeEvery(GET_GENERAL_ASSET_CLASS.request, getGeneralAssetClass);

  const changeTermIdAction = getReduxFormChangeActionType(
    'basketConstructor',
    STRATEGY_FIELD_NAME.TERM,
  );
  const changeOptionTypeIdAction = getReduxFormChangeActionType(
    'basketConstructor',
    STRATEGY_FIELD_NAME.PRODUCT_OPTION_TYPE_CODE,
  );
  const changePayoutIdAction = getReduxFormChangeActionType(
    'basketConstructor',
    STRATEGY_FIELD_NAME.PAYOUT_CODE,
  );

  const changeAnyInputAction = getReduxFormChangeActionType('basketConstructor', '');

  yield takeEvery(changePayoutIdAction, sideEffectChangePayoutId);
  yield takeEvery(changeTermIdAction, sideEffectChangeTerm);
  yield takeEvery(changeOptionTypeIdAction, sideEffectChangeOptionType);
  yield takeEvery(CHANGE_CURRENCY.start, sideEffectChangeCurrency);
  yield takeLatest(GET_REQUEST_DATA_FOR_COPY.request, getRequestDataForCopy);
  yield throttle(
    500,
    (action) => action.type.includes(changeAnyInputAction),
    sideEffectsChangeAnyInput,
  );

  yield takeEvery(GET_PRODUCT_TYPES_FORM_CREATING.request, getProductTypes);
  yield takeEvery(GET_MAX_GUARANTEE_LEVEL.request, getMaxGuaranteeLevel);
}
