import React from 'react';
import PropTypes from 'prop-types';
import { block } from 'bem-cn';
import { KEY_NAMES } from 'consts';
import { onlyNumbers, removeLeadingZeros } from 'helpers';
import BarrierField from './barrier';
import { getSettings, runBarrierAction } from './barrierUtils';
import './styles.scss';

const b = block('barrier-fields');

const propTypes = {
  count: PropTypes.number,
  values: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])),
  onChange: PropTypes.func,
  minValue: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  errors: PropTypes.object,
  generalError: PropTypes.string,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  showError: PropTypes.bool,
};

const defaultProps = {
  count: 1,
  values: [],
  errors: {},
  onChange: () => null,
  generalError: '',
  description: '',
  disabled: false,
  showError: false,
};

class BarrierFields extends React.Component {
  refsInput = {};

  handleChange = ({ target: { value } }, index) => {
    const {
      minValue,
      maxValue,
      values,
      onChange,
    } = this.props;

    const orderNumberMax = maxValue ? String(maxValue).length : 3;
    const { isQ } = getSettings({
      minValue,
      maxValue,
      value,
    });

    if (String(value).length > orderNumberMax) {
      if (this.refsInput[index + 1]) {
        this.refsInput[index + 1].focus();
      }

      return null;
    }

    if (isQ) {
      return null;
    }

    runBarrierAction({
      index,
      minValue,
      maxValue,
      value,
      inputRefs: this.refsInput,
    });

    const copyValues = [...values];
    copyValues[index] = removeLeadingZeros(onlyNumbers(value));

    onChange(copyValues);

    return null;
  };

  handleSaveRefInput = (el, index) => { this.refsInput[index] = el; };

  handleKeyDown = (e, index) => {
    const { values } = this.props;
    const ref = this.refsInput[index - 1];
    const value = values[index] || '';
    if (e.key === KEY_NAMES.KEY_BACKSPACE && !String(value).length && ref) {
      ref.focus();
    }
  };

  render() {
    const {
      count,
      values,
      disabled,
      errors,
      generalError,
      description,
      showError,
    } = this.props;
    const barriersTemplate = [...Array(count).keys()];

    return (
      <div className={b()}>
        <div className={b('barriers')}>
          {barriersTemplate.map((item, index) => (
            <div
              className={b('barrier-wrapper')}
              key={`${item}barrier`}
            >
              <BarrierField
                name={`${item}barrier`}
                index={index}
                value={values[index]}
                saveRefInput={this.handleSaveRefInput}
                onChange={this.handleChange}
                onKeyDown={this.handleKeyDown}
                disabled={disabled}
                isError={errors[index]}
              />
              <div>
                <span className={b('percent')}>%</span>
                {index !== barriersTemplate.length - 1 ?
                  <span className={b('separator')}>-</span> : null}
              </div>
            </div>
          ))}
        </div>
        {showError && <p className={b('error')}>{generalError}</p>}
        {barriersTemplate.length > 0 && (<p className={b('error')}>{description}</p>)}
      </div>
    );
  }
}
BarrierFields.propTypes = propTypes;
BarrierFields.defaultProps = defaultProps;
export default BarrierFields;
