import { createParamRoute } from '../helpers/routeHelper';

const setPrefix = (prefix, routes) => Object.keys(routes)
  .reduce((pathes, routeName) => ({
    ...pathes,
    [routeName]: `${prefix}${routes[routeName]}`,
  }), {});

const products = setPrefix('/product', {
  nszCalculation: '/nsz-calculation',
  preliminaryCalculation: '/preliminary-calculation',
  insuredData: '/insured-data',
  insurerData: '/insurer-data',
  execution: '/execution-isz',
  executionNsz: '/execution-nsz',
  beneficiaries: '/beneficiaries-data-isz',
  beneficiariesNsz: '/beneficiaries-data-nsz',
  printForm: '/print-form',
  constructorIszEditing: '/constructor-isz/edit',
  nszInsuredData: '/insured-data-nsz',
  nszInsurerData: '/insurer-data-nsz',
  kszParameters: '/parameters-ksz',
  kszInsurerData: '/insurer-data-ksz',
  kszInsuredData: '/insured-data-ksz',
  kszBeneficiaries: '/beneficiaries-data-ksz',
  kszExecution: '/execution-ksz',
});

const auth = setPrefix('/auth', {
  signIn: '/signin',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
});

const underwriting = setPrefix('/underwriting', {
  checkMaster: '/master',
  checkMasterEditor: '/editor',
  checkDocumentList: '/list',
});

const partnersAdministration = setPrefix('/partners', {
  createPartner: '/create',
  updatePartner: '/update',
});

const publicApiAdministration = setPrefix('/public-api', {
  createPublicApi: '/create',
  updatePublicApi: '/update',
});

const branchAdministration = setPrefix('/branches', {
  createBranch: '/create',
  updateBranch: '/update',
});

const userAdministration = setPrefix('/users', {
  createUser: '/create',
  updateUser: '/update',
});

const reportsAdministration = setPrefix('/reports', {
  createReport: '/create',
  updateReport: '/update',
});

const docsTransferService = setPrefix('/docs-transfer', {
  createDocsTransferMessage: '/create',
  inboxDocsTransferMessages: '/inbox',
  sentDocsTransferMessages: '/sent',
});

const administrationSection = setPrefix('/administration', {
  addPartners: '/partners',
  ...partnersAdministration,
  addSubdivisions: '/branches',
  ...reportsAdministration,
  ...branchAdministration,
  ...userAdministration,
  addUsers: '/users',
  addProducts: '/distributing-products',
  addReports: '/reports',
  locking: '/locking',
  statusControl: '/contract-statuses',
  catalogsSection: '/catalogs-section',
  schedulerJobs: '/scheduler-jobs',
  publicApi: '/public-api',
  ...publicApiAdministration,
  templatesUpload: '/templates-upload',
});

const ROUTES = {
  notFound: '/not-found',
  home: '/',
  myClients: '/sales',
  documentCheckStatus: '/document-check-status',
  customerReportingService: '/customer-reporting-service',
  docsTransfer: '/docs-transfer',
  importForm: '/import',
  administration: '/administration',
  events: '/events',
  transferData: '/transfer-data-export',
  transferDataImportManual: '/transfer-data-import-manual',
  transferDataImportAuto: '/transfer-data-import-auto',
  contests: '/contests',
  partnerChannel: '/partner-channel',
  ...products,
  ...auth,
  ...underwriting,
  ...administrationSection,
  ...docsTransferService,
  constructorIsz: createParamRoute('/products/:id/constructor-isz/ready-and-regular-baskets'),
  constructorIszCreating: createParamRoute('/products/:id/constructor-isz'),
  constructorIszPreparedBaskets: createParamRoute('/products/:id/constructor-isz/calculated-person-baskets'),
  clientTest: '/t',
};

export default ROUTES;
