import React, { memo } from 'react';
import { block } from 'bem-cn';
import PropTypes from 'prop-types';

const propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  index: PropTypes.number.isRequired,
  saveRefInput: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isError: PropTypes.bool,
};

const defaultProps = {
  value: '',
  disabled: false,
  isError: false,
};

const b = block('barrier-fields');

const BarrierField = ({
  value,
  index,
  saveRefInput,
  onChange,
  onKeyDown,
  disabled,
  isError,
}) => {
  const handleChange = (e) => onChange(e, index);
  const handleSaveRefInput = (e) => saveRefInput(e, index);
  const handleKeyDown = (e) => onKeyDown(e, index);

  return (
    <input
      value={value}
      ref={handleSaveRefInput}
      onChange={handleChange}
      className={b('input', { error: isError }).toString()}
      onKeyDown={handleKeyDown}
      disabled={disabled}
    />
  );
};

BarrierField.propTypes = propTypes;
BarrierField.defaultProps = defaultProps;
export default memo(BarrierField);
