import React from 'react';
import PropTypes from 'prop-types';
import { block } from 'bem-cn';
import { LANG_DICTIONARY } from 'consts';
import { PopupContainer } from 'components';
import './styles.scss';
import { Icon } from 'semantic-ui-react';

const b = block('testing-success-alert');

const {
  CLIENT_TEST_SUCCESS_TITLE,
  CLIENT_TEST_SUCCESS_MESSAGE,
} = LANG_DICTIONARY;

const propTypes = {
  isActive: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  isActive: false,
  className: '',
};

const TestSuccessMessage = ({
  isActive,
  onClose,
  className,
}) => (
  <PopupContainer isOpen={isActive} close={onClose} className={className}>
    <div className={b()}>
      <div className={b('title')}>{CLIENT_TEST_SUCCESS_TITLE}</div>
      <p className={b('message')}>{CLIENT_TEST_SUCCESS_MESSAGE}</p>
      <Icon name="check circle outline" size="massive" color="green" />
    </div>
  </PopupContainer>
);

TestSuccessMessage.propTypes = propTypes;
TestSuccessMessage.defaultProps = defaultProps;

export default TestSuccessMessage;
