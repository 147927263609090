import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn';
import { connect } from 'react-redux';
import { withCustomRouter } from 'HOC';
import { useParams } from 'react-router-dom';
import { Button, Form, Radio } from 'semantic-ui-react';
import {
  getQuesitons,
  checkTestLink,
  updateTestStatus,
  setClientTestingParams,
} from 'redux/actions';
import { LANG_DICTIONARY, ROUTES } from 'consts';

import './styles.scss';
import UnsuccessfulTestMessage from 'components/_popups/UnsuccessfulTestMessage';
import { getGlobalHistory, ErrorMessage } from 'components';
import NewLinkMessage from 'components/_popups/NewLinkMessage';
import TestSuccessMessage from 'components/_popups/TestSuccessMessage';

const { CLIENT_TEST_TITLE, COMPLETE, PAGE_NOT_FOUND_TEXT } = LANG_DICTIONARY;

const propTypes = {
  getAllQuestions: PropTypes.func,
  questions: PropTypes.array,
  UpdateTestStatus: PropTypes.func,
  CheckTestLink: PropTypes.func,
  clientTestRecordId: PropTypes.number,
  isShowTestSuccessMessage: PropTypes.bool,
  isShowUnsuccessfulTestMessage: PropTypes.bool,
  isShowNewLinkMessage: PropTypes.bool,
  SetClientTestingParams: PropTypes.func,
  token: PropTypes.string,
};

const defaultProps = {
  getAllQuestions: () => null,
  questions: [],
  UpdateTestStatus: () => null,
  CheckTestLink: () => null,
  clientTestRecordId: 0,
  isShowTestSuccessMessage: false,
  isShowUnsuccessfulTestMessage: false,
  isShowNewLinkMessage: false,
  SetClientTestingParams: () => null,
  token: '',
};

const b = block('client-test');

const ClientTest = ({
  getAllQuestions,
  questions,
  UpdateTestStatus,
  CheckTestLink,
  clientTestRecordId,
  isShowTestSuccessMessage,
  isShowUnsuccessfulTestMessage,
  isShowNewLinkMessage,
  SetClientTestingParams,
  token,
}) => {
  const { linkToken } = useParams();
  const [answers, setAnswers] = useState({});
  const [error, setError] = useState('');

  const handleAnswerChange = (questionId, value) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: value,
    }));
  };

  const handleSubmit = () => {
    const allCorrect = questions.every((question) => question.answers.every(
      (answer) => !(answers[question.id] === answer.id && !answer.correctAnswer),
    ));
    const formattedAnswers = Object.keys(answers).map((questionId) => ({
      [questionId]: answers[questionId],
    }));
    UpdateTestStatus({ id: clientTestRecordId, testResult: allCorrect, answers: formattedAnswers });
    SetClientTestingParams({ isNeedTesting: false, clientTestRecordId: 0 });
  };

  useEffect(() => {
    getAllQuestions();
  }, [getAllQuestions]);

  useEffect(() => {
    if (clientTestRecordId < 0) {
      setError(PAGE_NOT_FOUND_TEXT);
    }
  }, [clientTestRecordId]);

  useEffect(() => {
    CheckTestLink({ linkToken });
  }, [checkTestLink]);

  useEffect(() => {
    if (token) {
      getGlobalHistory().push(ROUTES.notFound);
    }
  }, []);

  return (
    <div className={b()}>
      <TestSuccessMessage isActive={isShowTestSuccessMessage} />
      <UnsuccessfulTestMessage isActive={isShowUnsuccessfulTestMessage} />
      <NewLinkMessage isActive={isShowNewLinkMessage} />
      <div className={b('form')}>
        { error ? (
          <ErrorMessage message={error} />
        ) : (
          <>
            <h2 className={b('header')}>{ CLIENT_TEST_TITLE }</h2>
            <Form onSubmit={handleSubmit}>
              <div>
                {questions.map((question, index) => (
                  <div className={b('field-block')} key={question.id}>
                    <div className={b('filed-block-label')}>{`${index + 1}. ${question.question}`}</div>
                    <div className={b('answers-container')}>
                      {question.answers.map((answer) => (
                        <div className={b('field')} key={answer.id}>
                          <Radio
                            label={answer.answer}
                            name={`question_${question.id}`}
                            value={answer.id}
                            checked={answers[question.id] === answer.id}
                            onChange={() => handleAnswerChange(question.id, answer.id)}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
              <Button className={b('submit-btn')} type="submit" disabled={Object.keys(answers).length < questions.length}>{ COMPLETE }</Button>
            </Form>
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  questions: state.authReducer.questions,
  clientTestRecordId: state.authReducer.clientTestRecordId,
  isShowTestSuccessMessage: state.authReducer.isShowTestSuccessMessage,
  isShowUnsuccessfulTestMessage: state.authReducer.isShowUnsuccessfulTestMessage,
  isShowNewLinkMessage: state.authReducer.isShowNewLinkMessage,
  clientTestingProduct: state.authReducer.clientTestingProduct,
  token: state.authReducer.token,
});

const mapDispatchToProps = {
  getAllQuestions: getQuesitons,
  UpdateTestStatus: updateTestStatus,
  CheckTestLink: checkTestLink,
  SetClientTestingParams: setClientTestingParams,
};

ClientTest.propTypes = propTypes;
ClientTest.defaultProps = defaultProps;

export default withCustomRouter(connect(mapStateToProps, mapDispatchToProps)(ClientTest));
