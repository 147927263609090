import React from 'react';
import PropTypes from 'prop-types';
import { PopupContainer } from 'components';
import { Button } from 'semantic-ui-react';
import { block } from 'bem-cn';
import { LANG_DICTIONARY } from 'consts';

import './styles.scss';

const b = block('client-test-record-exists');

const { CLIENT_TEST_SUCCESS_RECORD_EXISTS_MESSAGE, GO_TO_CHECKOUT } = LANG_DICTIONARY;

const propTypes = {
  isActive: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  isActive: false,
  className: b(),
};

const SuccessRecordExistsMessage = ({
  isActive,
  onClose,
  onOk,
  className,
}) => (
  <PopupContainer isOpen={isActive} className={className} close={onClose}>
    <div className={b('message')}>
      { CLIENT_TEST_SUCCESS_RECORD_EXISTS_MESSAGE }
    </div>
    <div className={b('controls')}>
      <Button
        type="submit"
        className={b('sendButton').toString()}
        onClick={onOk}
      >
        { GO_TO_CHECKOUT }
      </Button>
    </div>
  </PopupContainer>
);

SuccessRecordExistsMessage.propTypes = propTypes;
SuccessRecordExistsMessage.defaultProps = defaultProps;

export default SuccessRecordExistsMessage;
