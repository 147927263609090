import React from 'react';
import PropTypes from 'prop-types';
import { block } from 'bem-cn';
import { LANG_DICTIONARY } from 'consts';
import { PopupContainer } from 'components';

import './styles.scss';
import { Icon } from 'semantic-ui-react';

const b = block('unsuccessful-test-message');

const {
  CLIENT_TEST_UNSUCCESSFUL_MESSAGE_TITLE,
  CLIENT_TEST_UNSUCCESSFUL_MESSAGE_TEXT,
} = LANG_DICTIONARY;

const propTypes = {
  isActive: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  isActive: false,
  className: '',
};

const UnsuccessfulTestMessage = ({
  isActive,
  onClose,
  className,
}) => (
  <PopupContainer isOpen={isActive} close={onClose} className={className}>
    <div className={b()}>
      <div className={b('title')}>{ CLIENT_TEST_UNSUCCESSFUL_MESSAGE_TITLE }</div>
      <p className={b('message')}>{ CLIENT_TEST_UNSUCCESSFUL_MESSAGE_TEXT }</p>
      <Icon name="exclamation circle" size="massive" color="red" />
    </div>
  </PopupContainer>
);

UnsuccessfulTestMessage.propTypes = propTypes;
UnsuccessfulTestMessage.defaultProps = defaultProps;

export default UnsuccessfulTestMessage;
